import { post } from "aws-amplify/api";
import {
  BLINGS_API_NAME,
  NEW_REST_API__V2_CREATE_SCRIPT,
} from "../consts/consts";

type OpenAIResponse = {
  script?: {
    message?: {
      content?: string;
    };
  }[];
};

export async function callOpenAIEndpoint(prompt: string): Promise<string> {
  try {
    const request = post({
      apiName: BLINGS_API_NAME,
      path: NEW_REST_API__V2_CREATE_SCRIPT,
      options: {
        body: { prompt },
      },
    });

    const res = await request.response;

    let content: OpenAIResponse | null = null;
    try {
      const text = await res.body.text();
      content = JSON.parse(text);
    } catch (error) {
      throw new Error("Error on anylise OPENAI response");
    }

    if (
      !content ||
      !content.script ||
      content.script.length === 0 ||
      !content.script[0].message?.content
    ) {
      throw new Error("Invalid OPENAI response");
    }

    return content.script[0].message.content;
  } catch (error) {
    console.error("Endpoint error Amplify/OpenAI:", error);
    throw error;
  }
}
